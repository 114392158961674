/* import __COLOCATED_TEMPLATE__ from './pricing-card-cta.hbs'; */
/* RESPONSIBLE TEAM: team-purchase */
import Component from '@glimmer/component';

export interface Args {
  type: 'primary' | 'secondary';
  label: string;
  dataIntercomBotTarget?: string | undefined;
  onClick: () => void;
}

interface Signature {
  Args: Args;
  Blocks: any;
  Element: HTMLButtonElement;
}

export default class PricingCardCta extends Component<Signature> {
  get isPrimary() {
    return this.args.type === 'primary';
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Modal::CardlessConversionModalPricing5::PricingCardCta': typeof PricingCardCta;
    'modal/cardless-conversion-modal-pricing5/pricing-card-cta': typeof PricingCardCta;
  }
}
