/* import __COLOCATED_TEMPLATE__ from './apply-for-early-stage-link.hbs'; */
/* RESPONSIBLE TEAM: team-purchase */
import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import Component from '@glimmer/component';
import {
  PRICING_5_X_EARLY_STAGE_SOLUTION_ID,
  percentDiscountForSolution,
} from 'embercom/lib/billing';

export default class ApplyForEarlyStageLink extends Component {
  @service router;
  @service purchaseAnalyticsService;
  @service appService;
  @service cardlessConversionModalService;

  get earlyStageDiscount() {
    return percentDiscountForSolution(PRICING_5_X_EARLY_STAGE_SOLUTION_ID);
  }

  @action
  transitionToEarlyStageApplication() {
    this.purchaseAnalyticsService.trackEvent({
      action: 'clicked',
      context: 'cardless_trial',
      place: 'cardless_conversion_modal',
      object: 'or_apply_for_early_stage_link',
    });
    if (this.cardlessConversionModalService.showModal) {
      this.cardlessConversionModalService.hide();
    }
    this.router.transitionTo(
      'apps.app.teams-checkout.early-stage-application',
      this.appService.app.id,
      {
        queryParams: {
          solution_id: PRICING_5_X_EARLY_STAGE_SOLUTION_ID,
        },
      },
    );
  }
}
