/* import __COLOCATED_TEMPLATE__ from './jwt-installation-snippet.hbs'; */
/* RESPONSIBLE TEAM: team-messenger */
import Component from '@glimmer/component';
import { PROGRAMMING_LANGUAGES } from 'embercom/components/installation-new/constants';

interface Signature {
  Element: HTMLElement;
  Args: {
    selectedBackendInstallationMethod: PROGRAMMING_LANGUAGES;
  };
}

/* eslint-disable @intercom/intercom/no-bare-strings */
export default class JwtInstallationSnippet extends Component<Signature> {
  get codeLanguage() {
    return this.args.selectedBackendInstallationMethod === PROGRAMMING_LANGUAGES.JAVA
      ? 'xml'
      : 'bash';
  }

  get installJwtLibraryCodeSnippet() {
    switch (this.args.selectedBackendInstallationMethod) {
      case PROGRAMMING_LANGUAGES.NODE:
        return 'npm install jsonwebtoken';
      case PROGRAMMING_LANGUAGES.RAILS:
        return 'gem install jwt';
      case PROGRAMMING_LANGUAGES.DJANGO:
        return 'pip install pyjwt';
      case PROGRAMMING_LANGUAGES.PHP:
        return 'composer require firebase/php-jwt';
      case PROGRAMMING_LANGUAGES.GO:
        return 'go get -u github.com/golang-jwt/jwt';
      case PROGRAMMING_LANGUAGES.JAVA:
        return `<!-- Add to pom.xml -->
<dependency>
    <groupId>io.jsonwebtoken</groupId>
    <artifactId>jjwt</artifactId>
    <version>0.9.1</version>
</dependency>`;
      default:
        return 'Package manager command not found';
    }
  }
}
/* eslint-enable @intercom/intercom/no-bare-strings */

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'MessengerSettings::Security::CodeSnippets::JwtInstallationSnippet': typeof JwtInstallationSnippet;
  }
}
