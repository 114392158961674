/* import __COLOCATED_TEMPLATE__ from './single-page-component.hbs'; */
/* RESPONSIBLE TEAM: team-messenger */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable @intercom/intercom/no-component-inheritance */
import BaseInstallMethodComponent from 'embercom/components/messenger-installation/install-methods/base-install-method-component';
import { selectFromObject } from '@intercom/pulse/lib/computed-properties';
import { reads } from '@ember/object/computed';
import { computed, action } from '@ember/object';
import { inject as service } from '@ember/service';

const UNKNOWN_HMAC_METHOD_TEXT = '"INSERT_HMAC_VALUE_HERE"';

export default BaseInstallMethodComponent.extend({
  intercomEventService: service(),
  codeOptions: [
    { text: 'Node.js', value: 'node' },
    { text: 'Rails (Ruby)', value: 'rails' },
    { text: 'Django (Python 3)', value: 'django' },
    { text: 'PHP', value: 'php' },
  ],
  selectedLanguage: reads('codeOptions.firstObject.value'),
  hmacServerSideLanguage: reads('codeOptions.firstObject.value'),
  placeholderUserHash: reads('app.hasNewMessengerInstallationPage'),
  identificationHMACGenerator: 'user_id',
  identifierVerifiedBy: computed('identificationHMACGenerator', function () {
    if (this.identificationHMACGenerator === 'user_id') {
      return 'id';
    }
    return 'email';
  }),
  setServerSideLanguage: action(function (value) {
    this.intercomEventService.trackAnalyticsEvent({
      action: 'select',
      object: 'server_side_language',
      place: 'messenger_installation_settings',
      context: value,
    });
    this.set('selectedLanguage', value);
    this.set('hmacServerSideLanguage', value);
  }),

  changeHMACGenerator: action(function (identifier) {
    this.set('identificationHMACGenerator', identifier);
  }),

  examples: computed('appApiSecret', 'identifierVerifiedBy', 'placeholderUserHash', function () {
    let appApiSecret = this.appApiSecret;
    let identifierVerifiedBy = this.identifierVerifiedBy;
    let unknownHmacMethodPlaceholderText = UNKNOWN_HMAC_METHOD_TEXT;
    let showPlaceholderUserHash = this.placeholderUserHash;

    return {
      rails: {
        name: '"<%= current_user.name %>"',
        userID: '"<%= current_user.id %>"',
        email: '"<%= current_user.email %>"',
        createdAt: '"<%= current_user.created_at.to_i %>"',
        userHash: showPlaceholderUserHash
          ? `'<%= ${unknownHmacMethodPlaceholderText} %>'`
          : `"<%=
          OpenSSL::HMAC.hexdigest(
            'sha256',
            '${appApiSecret}',
            current_user.${identifierVerifiedBy}
          )
        %>"`,
      },
      django: {
        name: '"{{ request.user.name|escapejs }}"',
        userID: '"{{ request.user.id|escapejs }}"',
        email: '"{{ request.user.email|escapejs }}"',
        createdAt: '"{{ request.user.date_joined|date:"U" }}"',
        userHash: showPlaceholderUserHash
          ? `'{{ ${unknownHmacMethodPlaceholderText} }}'`
          : `"{{
        hmac.new(
          b'${appApiSecret}',
          bytes(request.user.${identifierVerifiedBy}, encoding='utf-8'),
          digestmod=hashlib.sha256
        ).hexdigest()
      }}"`,
      },
      php: {
        name: '<?php echo json_encode($current_user->name) ?>',
        userID: '<?php echo json_encode($current_user->id) ?>',
        email: '<?php echo json_encode($current_user->email) ?>',
        createdAt: '"<?php echo strtotime($current_user->created_at) ?>"',
        userHash: showPlaceholderUserHash
          ? `<?php echo ${unknownHmacMethodPlaceholderText} ?>`
          : `"<?php
      echo hash_hmac(
        'sha256',
        $user->${identifierVerifiedBy},
        '${appApiSecret}'
      );
    ?>"`,
      },
      node: {
        name: 'user.name',
        userID: 'user.id',
        email: 'user.email',
        createdAt: 'user.createdAt',
        userHash: unknownHmacMethodPlaceholderText,
      },
    };
  }),
  example: selectFromObject('examples', 'selectedLanguage'),
});
