/* import __COLOCATED_TEMPLATE__ from './new-conversation-slot.hbs'; */
/* RESPONSIBLE TEAM: team-messenger */
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';

export default class NewConversationSlotComponent extends Component {
  @service messengerSettingsTargetUserProvider;
  @service appService;
  @service router;
  @service intl;

  get app() {
    return this.appService.app;
  }

  get userTypeLabel() {
    return this.messengerSettingsTargetUserProvider.isUserType
      ? this.intl.t('messenger.target-types.users-label')
      : this.intl.t('messenger.target-types.visitors-label');
  }

  get inboundConversationsDisabled() {
    if (this.messengerSettingsTargetUserProvider.isUserType) {
      return !this.args.configuration.inboundConversationsEnabledForUsers;
    } else {
      return !this.args.configuration.inboundConversationsEnabledForVisitors;
    }
  }

  get preventMultipleInboundConversationsEnabled() {
    if (this.messengerSettingsTargetUserProvider.isUserType) {
      return this.args.configuration.preventMultipleInboundConversationEnabledForUsers;
    } else {
      return this.args.configuration.preventMultipleInboundConversationEnabledForVisitors;
    }
  }

  get showWarning() {
    return this.inboundConversationsDisabled || this.preventMultipleInboundConversationsEnabled;
  }

  get inboundVolumeSettingsUrl() {
    return this.router.urlFor('apps.app.settings.channels.messenger.general', this.app, {
      queryParams: { section: 'inbound-volume' },
    });
  }

  get conversationButtonTextUrl() {
    return this.router.urlFor('apps.app.settings.channels.messenger.conversations', this.app, {
      queryParams: { tab: 'teammates', section: 'start-conversation-button' },
    });
  }
}
