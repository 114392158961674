/* import __COLOCATED_TEMPLATE__ from './android-component.hbs'; */
/* RESPONSIBLE TEAM: team-messenger */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable @intercom/intercom/no-component-inheritance */
/* eslint-disable @intercom/intercom/no-bare-strings */
import BaseInstallMethodComponent from 'embercom/components/messenger-installation/install-methods/base-install-method-component';
import { computed, action } from '@ember/object';
import { or } from '@ember/object/computed';
import { A } from '@ember/array';

export default BaseInstallMethodComponent.extend({
  language: 'java',
  hmacServerSideLanguage: 'node',
  sdkApiKey: or('apiKey', 'androidSdkApp.api_key'),
  sdkApiSecret: or('apiSecret', 'androidSdkApp.api_secret'),
  secretKeys: computed('sdkApiSecret', function () {
    return A([{ userSource: 'Android', key: this.sdkApiSecret }]);
  }),

  identificationHMACGenerator: 'user_id',

  changeHMACGenerator: action(function (identifier) {
    this.set('identificationHMACGenerator', identifier);
  }),
});
