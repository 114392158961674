/* import __COLOCATED_TEMPLATE__ from './launch-to-conversation.hbs'; */
/* RESPONSIBLE TEAM: team-messenger */
import { action } from '@ember/object';
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import type MessengerSettingsTargetUserProvider from 'embercom/services/messenger-settings-target-user-provider';

export interface Args {
  visibilitySettings: $TSFixMe;
  homeSettings: $TSFixMe;
  isMobilePlatform: boolean;
}

export default class LaunchToConversation extends Component<Args> {
  @service declare router: any;
  @service declare appService: any;
  @service declare messengerSettingsTargetUserProvider: MessengerSettingsTargetUserProvider;
  @service declare MessengerSettingsService: any;

  get app() {
    return this.appService.app;
  }

  get visibilitySettings() {
    return this.args.visibilitySettings;
  }

  get launchToConversationEnabled() {
    return this.visibilitySettings.launchToConversationEnabled;
  }

  get isMobileOrWebUserType() {
    return this.messengerSettingsTargetUserProvider.isMobileOrWebUserType;
  }

  get inboundVolumeSettingsUrl() {
    return this.router.urlFor('apps.app.settings.channels.messenger.general', this.app, {
      queryParams: { section: 'inbound-volume' },
    });
  }

  get showWarning() {
    return (
      this.visibilitySettings.launchToConversationEnabled &&
      (!this.visibilitySettings.inboundConversationsEnabled ||
        this.visibilitySettings.preventMultipleInboundConversationEnabled ||
        this.args.homeSettings.requireSearchBrowse)
    );
  }

  get learnMoreUrl() {
    return 'https://www.intercom.com/help/en/articles/6612589-set-up-and-customize-the-messenger#h_e609891306';
  }

  @action
  toggleLaunchToConversationEnabled() {
    this.visibilitySettings.launchToConversationEnabled = !this.launchToConversationEnabled;
    let screen = this.visibilitySettings.launchToConversationEnabled ? 'conversation' : 'home';
    this.MessengerSettingsService.onPreviewScreenChange(screen);
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'MessengerSettings::Content::LaunchToConversation': typeof LaunchToConversation;
    'messenger-settings/content/launch-to-conversation': typeof LaunchToConversation;
  }
}
