/* import __COLOCATED_TEMPLATE__ from './set-expectation.hbs'; */
/* RESPONSIBLE TEAM: team-messenger */
import { inject as service } from '@ember/service';
import Component from '@glimmer/component';
import type StartConversationButtonSettings from 'embercom/models/messenger-settings/start-conversation-button-text';
import type MessengerSettingsTargetUserProvider from 'embercom/services/messenger-settings-target-user-provider';

interface Args {
  value: StartConversationButtonSettings['setExpectations'];
  updateValue: (value: StartConversationButtonSettings['setExpectations']) => void;
  inboundConversationsDisabled: boolean;
  preventMultipleInboundConversationEnabled: boolean;
  locale: string;
}

export default class SetExpectation extends Component<Args> {
  @service declare appService: any;
  @service declare messengerSettingsTargetUserProvider: MessengerSettingsTargetUserProvider;
  @service declare router: any;

  get isUserType() {
    return this.messengerSettingsTargetUserProvider.isUserType;
  }

  get withTeammatesTabUrl() {
    return this.router.urlFor(
      'apps.app.settings.channels.messenger.conversations',
      this.appService.app,
      {
        queryParams: { tab: 'teammates', section: 'start-conversation-button' },
      },
    );
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'MessengerSettings::Content::SetExpectation': typeof SetExpectation;
  }
}
