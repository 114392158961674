/* import __COLOCATED_TEMPLATE__ from './install-method-wrapper-component.hbs'; */
/* RESPONSIBLE TEAM: team-messenger */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file: 🚀 */
/* eslint-disable @intercom/intercom/no-bare-strings */
/* eslint-disable ember/no-actions-hash */
/* eslint-disable ember/no-classic-classes */
/* eslint-disable ember/no-classic-components */
/* eslint-disable @intercom/intercom/require-empty-tagname */
import Component from '@ember/component';
import { reads, readOnly } from '@ember/object/computed';
import { get, computed, action } from '@ember/object';
import { inject as service } from '@ember/service';
import { VerificationKeys } from 'embercom/lib/messenger/messenger-verification';
import safeWindowOpen from 'embercom/lib/safe-window-open';
import { showNewMessageInIntercomWidget } from 'embercom/lib/intercom-widget-helper';
import {
  AUDIENCE,
  INSTALL_METHODS,
  INSTALL_METHOD_ANALYTICS_NAMES,
} from 'embercom/lib/messenger/installation-constants';
import SdkApp from 'embercom/models/sdk-app';
import { task } from 'ember-concurrency';

const BASE_INSTALL_METHOD_COMPONENT_PATH = 'messenger-installation/install-methods/';

const EXAMPLE_PROJECT_URLS = {
  [AUDIENCE.LOGGED_OUT]: {
    [INSTALL_METHODS.JAVASCRIPT.value]:
      'https://github.com/intercom/example-basic-javascript-install/tree/visitor',
    [INSTALL_METHODS.SINGLE_PAGE.value]:
      'https://github.com/intercom/example-single-page-app-install/tree/visitor-react-router',
  },
  [AUDIENCE.LOGGED_IN]: {
    [INSTALL_METHODS.JAVASCRIPT.value]:
      'https://github.com/intercom/example-basic-javascript-install/tree/logged-in',
    [INSTALL_METHODS.SINGLE_PAGE.value]:
      'https://github.com/intercom/example-single-page-app-install/tree/logged-in-react-router',
  },
};

export default Component.extend({
  intercomEventService: service(),
  store: service(),
  appService: service(),
  app: readOnly('appService.app'),
  admin: readOnly('app.currentAdmin'),
  iOSSdkApp: null,
  androidSdkApp: null,
  dropdownItems: null,
  selectedIntegrationType: null,
  defaultInstallMethod: computed(
    'dropdownItems.@each.{text,value}',
    'dropdownItems.firstObject.value',
    'selectedIntegrationType',
    function () {
      return (
        this.dropdownItems?.findBy('value', this.selectedIntegrationType)?.value ||
        this.dropdownItems?.firstObject?.value
      );
    },
  ),
  selectedAudience: null,
  selectedInstallMethod: reads('defaultInstallMethod'),

  selectedInstallMethodText: computed(
    'selectedInstallMethod',
    'dropdownItems.@each.{value,text}',
    function () {
      return this.dropdownItems?.findBy('value', this.selectedInstallMethod).text;
    },
  ),

  selectedInstallMethodExampleProjectUrl: computed(
    'selectedAudience',
    'selectedInstallMethod',
    function () {
      return get(EXAMPLE_PROJECT_URLS, `${this.selectedAudience}.${this.selectedInstallMethod}`);
    },
  ),

  verificationKey: computed('selectedAudience', function () {
    switch (this.selectedAudience) {
      case AUDIENCE.LOGGED_OUT:
        return VerificationKeys.LOGGED_OUT;
      case AUDIENCE.LOGGED_IN:
        return VerificationKeys.LOGGED_IN;
      default:
        return VerificationKeys.ANY_PING;
    }
  }),

  installMethodComponentName: computed(
    'selectedAudience',
    'selectedCodeInstallType',
    'selectedInstallMethod',
    function () {
      let name = BASE_INSTALL_METHOD_COMPONENT_PATH;
      if (this.selectedCodeInstallType && this.selectedAudience) {
        name = name.concat(`${this.selectedAudience}/`);
      }
      name = name.concat(`${this.selectedInstallMethod}-component`);
      return name;
    },
  ),

  baseAnalyticsMetadata: null,

  disableBannerPlatform: computed('selectedInstallMethod', function () {
    switch (this.selectedInstallMethod) {
      case INSTALL_METHODS.IOS.value:
        return 'iOS';
      case INSTALL_METHODS.ANDROID.value:
        return 'Android';
      default:
        return 'Web';
    }
  }),

  isMessengerDisabled: computed(
    'selectedInstallMethod',
    'app.isWebMessengerDisabled',
    'iOSSdkApp.isDisabled',
    'androidSdkApp.isDisabled',
    function () {
      switch (this.selectedInstallMethod) {
        case INSTALL_METHODS.IOS.value:
          return this.iOSSdkApp?.isDisabled;
        case INSTALL_METHODS.ANDROID.value:
          return this.androidSdkApp?.isDisabled;
        default:
          return this.app.isWebMessengerDisabled;
      }
    },
  ),

  analyticsMetadata: computed('baseAnalyticsMetadata', 'selectedInstallMethod', function () {
    return {
      ...this.baseAnalyticsMetadata,
      context: INSTALL_METHOD_ANALYTICS_NAMES[this.selectedInstallMethod],
    };
  }),

  didUpdateAttrs() {
    this._super(...arguments);
    if (!this.dropdownItems?.map((d) => d.value)?.includes(this.selectedInstallMethod)) {
      this.set('selectedInstallMethod', this.defaultInstallMethod);
    }
  },

  setIOSSdkApp: task(function* () {
    if (!this.iOSSdkApp) {
      let sdkApp = yield SdkApp.findOrCreateForPlatform('iOS');
      this.set('iOSSdkApp', sdkApp);
    }
  }).drop(),

  setAndroidSdkApp: task(function* () {
    if (!this.androidSdkApp) {
      let sdkApp = yield SdkApp.findOrCreateForPlatform('Android');
      this.set('androidSdkApp', sdkApp);
    }
  }).drop(),

  setSdkAppForInstallMethod(method) {
    let { IOS, ANDROID, CORDOVA } = INSTALL_METHODS;
    if (this.app) {
      if ([IOS.value, CORDOVA.value].includes(method)) {
        this.setIOSSdkApp.perform();
      }
      if ([ANDROID.value, CORDOVA.value].includes(method)) {
        this.setAndroidSdkApp.perform();
      }
    }
  },

  sendAnalyticsEvent(analyticsMetadata) {
    this.intercomEventService.trackAnalyticsEvent({
      ...this.analyticsMetadata,
      ...analyticsMetadata,
    });
  },

  setSelectedInstallMethod: action(function (method) {
    this.set('selectedInstallMethod', method);
    this.setSdkAppForInstallMethod(method);
  }),

  openMessenger: action(function () {
    this.sendAnalyticsEvent({
      action: 'clicked',
      object: 'contact_us',
    });

    let installMethod = this.selectedInstallMethodText;
    showNewMessageInIntercomWidget(
      `Hey, I installed my Messenger with the ${installMethod} method but don't see it when I open my site/app.`,
    );
  }),

  actions: {
    goToExampleProject() {
      this.sendAnalyticsEvent({
        action: 'clicked',
        object: 'example_project_link',
        ...this.analyticsMetadata,
      });
      safeWindowOpen(this.selectedInstallMethodExampleProjectUrl, '_blank');
    },
  },
});
