/* RESPONSIBLE TEAM: team-frontend-tech */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable @intercom/intercom/no-bare-strings */
/* eslint-disable ember/no-new-mixins */
import { isNone } from '@ember/utils';
import EmberObject, { computed, get, set } from '@ember/object';
import Mixin from '@ember/object/mixin';
import { ternary, ternaryToProperty } from '@intercom/pulse/lib/computed-properties';
import { task } from 'ember-concurrency';
import { inject as service } from '@ember/service';
import ajax from 'embercom/lib/ajax';

export default Mixin.create({
  notificationsService: service(),
  store: service(),
  intercomEventService: service(),
  companyAttributeType: 'company',
  personAttributeType: ternary('person.isUserRole', 'user', 'lead'),
  editableObject: computed(
    'attribute.isCompany',
    'person.qualificationCompany',
    'person',
    function () {
      if (this.get('attribute.isCompany')) {
        return this.get('person.qualificationCompany');
      } else {
        return this.person;
      }
    },
  ),
  currentAttributeValue: computed(
    'attribute.identifierWithoutCompanyPrefix',
    'editableObject',
    'editableObject._lastEdit',
    function () {
      return get(this.editableObject, this.get('attribute.identifierWithoutCompanyPrefix'));
    },
  ),
  attributeType: ternaryToProperty(
    'attribute.isCompany',
    'companyAttributeType',
    'personAttributeType',
  ),

  updateAttributeValue: task(function* (value) {
    let existingValue = this.currentAttributeValue;
    try {
      this.setValue(value);
      let response = yield this._put(
        `/ember/user_attributes/${this.get('person.id')}`,
        this._generateParams(value),
      );
      this._updateLocalModel(response);
      this._trackAttributeUpdated();
    } catch (error) {
      this.notificationsService.notifyResponseError(error, {
        default: `Something went wrong and we couldn't update the value for '${this.get(
          'attribute.identifier',
        )}'.`,
      });
      this.setValue(existingValue);
      console.error(error);
    } finally {
      this.set('isEditing', false);
    }
  }).drop(),

  resyncAttributeData: task(function* () {
    let response = yield this._put(`/ember/user_attributes/reset`, this._generateParams());
    this._updateLocalModel(response);
  }).drop(),

  // The user serializer may not always have tag, segment, company data etc.
  // To prevent overwriting that data with null if it is not present in the response,
  // we merge the response data with the local copy.
  _updateLocalModel(response) {
    if (response) {
      this.store.pushPayload({ user: response });
      let user = this.store.peekRecord('user', response.id);
      user.loadSegments.perform();
      let participant = this.store.peekRecord('participant', response.id);
      if (participant) {
        participant.setProperties(response);
      }
    }
  },

  _trackAttributeUpdated() {
    this.intercomEventService.trackAnalyticsEvent({
      action: 'edited',
      object: 'editable_attribute',
      attribute_identifier: this.get('attribute.identifier'),
    });
  },

  _generateParams(value) {
    return {
      app_id: this.get('app.id'),
      id: this.get('person.id'),
      attribute_identifier: this.get('attribute.identifier'),
      attribute_value: value,
      attribute_belongs_to_type: this.attributeType,
      qualification_company_id: this.get('person.qualificationCompany.id'),
    };
  },

  setValue(value) {
    this._updateEditableObject(
      this.editableObject,
      this.get('attribute.identifierWithoutCompanyPrefix'),
      value,
    );
    this.editableObject.set('_lastEdit', new Date());
  },

  _performUpdateTask(taskName, value) {
    if (this.disabled) {
      throw new Error(
        `Attempted to update ${this.get('attribute.name')} but the editor is disabled`,
      );
    }
    this.get(taskName).perform(value);
  },
  // When setting a nested key, e.g. `custom_data.my_cda`
  // This function will initialise all nested objects down the identifier chain
  // before attempting to set the value. E.g. if `custom_data` does not exist
  // on the object, we will set an empty hash in its place before setting `my_cda`.
  _updateEditableObject(editableObject, attributeIdentifier, value) {
    let objectPaths = attributeIdentifier.split('.');
    objectPaths.pop();
    for (let i = 0; i < objectPaths.length; ++i) {
      let currentPath = objectPaths.slice(0, i + 1).join('.');
      let currentValueForPath = get(editableObject, currentPath);
      if (isNone(currentValueForPath)) {
        set(editableObject, currentPath, EmberObject.create());
      }
    }
    set(editableObject, attributeIdentifier, value);
  },

  _put(url, data) {
    return ajax({
      type: `PUT`,
      url,
      data: JSON.stringify(data),
    });
  },
});
