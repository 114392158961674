/* import __COLOCATED_TEMPLATE__ from './feature-row.hbs'; */
/* RESPONSIBLE TEAM: team-purchase */
import { action } from '@ember/object';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { type Feature } from 'embercom/lib/conversion-modal-pricing5-plan-groups';

interface Signature {
  Element: HTMLDivElement;
  Args: {
    feature: Feature;
    rowTitleWidth: string;
    isLoading?: boolean;
    nameKey?: string;
  };
}

export default class FeatureRow extends Component<Signature> {
  @tracked expanded = false;

  get chevronArrow() {
    if (this.expanded) {
      return 'thin-up-arrow';
    } else {
      return 'thin-down-arrow';
    }
  }

  @action
  onToggleExpandableFeature(expandable: boolean) {
    if (expandable) {
      this.expanded = !this.expanded;
    }
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Modal::CardlessConversionModalPricing5::FeatureRow': typeof FeatureRow;
  }
}
