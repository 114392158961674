/* import __COLOCATED_TEMPLATE__ from './install-method-selector.hbs'; */
/* RESPONSIBLE TEAM: team-messenger */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file: 🚀 */
/* eslint-disable ember/no-classic-classes */
/* eslint-disable ember/no-classic-components */
/* eslint-disable @intercom/intercom/require-empty-tagname */
import Component from '@ember/component';
import { inject as service } from '@ember/service';
import { computed, action } from '@ember/object';
import { INSTALL_TYPE } from 'embercom/lib/messenger/installation-constants';

export default Component.extend({
  attributeBindings: ['data-test-install-method-selector-component'],
  'data-test-install-method-selector-component': true,
  intercomEventService: service(),
  selectedInstallMethod: null,
  baseAnalyticsMetadata: null,
  installTypeCode: INSTALL_TYPE.CODE,
  installTypeThirdParty: INSTALL_TYPE.THIRD_PARTY,
  headerTitle: computed('installationTitle', function () {
    if (this.installationTitle) {
      return this.installationTitle;
    }
    return 'onboarding.home.get-ready-to-chat-with-your-customers.add-chat-to-your-website.choose-setup';
  }),

  analyticsMetadata: computed('baseAnalyticsMetadata', function () {
    return {
      ...this.baseAnalyticsMetadata,
      action: 'clicked',
    };
  }),

  sendAnalyticsEvent(object, install_method) {
    this.intercomEventService.trackAnalyticsEvent({
      ...this.analyticsMetadata,
      object,
      install_method,
    });
  },

  triggerAnalyticsWithCode: action(function () {
    this.sendAnalyticsEvent('with_code');
  }),

  triggerAnalyticsWithThirdParty: action(function () {
    this.sendAnalyticsEvent('with_third_party_app');
  }),
});
