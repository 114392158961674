/* RESPONSIBLE TEAM: team-knowledge-foundations */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable ember/no-get-with-default */
/* eslint-disable ember/no-new-mixins */
/* eslint-disable ember/no-jquery */
import { bind } from '@ember/runloop';
import $ from 'jquery';
import { isEmpty } from '@ember/utils';
import { on } from '@ember/object/evented';
import Mixin from '@ember/object/mixin';
export default Mixin.create({
  _setupSortable: on('didInsertElement', function () {
    let selector = this.sortableAreaSelector ?? '.sortable';
    let sortableArea = $(selector, this.element)[0];

    let sortable = Sortable.create(sortableArea, {
      sort: isEmpty(this.isSortable) ? true : this.isSortable,
      animation: 150,
      group: this.sortableGroup, // https://github.com/RubaXa/Sortable#group-option
      draggable: this.draggableSelector ?? '.draggable',
      handle: this.draggableHandleSelector, // undefined -> the whole item is a handle
      ghostClass: this.ghostClass ?? 'o__drop-ghost',
      onUpdate: this._getAndBindHandler('onDraggingUpdate'),
      onAdd: this._getAndBindHandler('onDragToOtherList'),
      onStart: this._getAndBindHandler('onDraggingStart'),
      onEnd: this._getAndBindHandler('onDraggingEnd'),
      onSort: this._getAndBindHandler('onSort'),
      onMove: this._getAndBindHandler('onMove'),
    });
    this.set('sortable', sortable);
  }),

  _getAndBindHandler(name) {
    let handler = this.get(name);
    if (handler) {
      handler = bind(this, handler);
    }
    return handler;
  },

  _tearDownSortable: on('willDestroyElement', function () {
    if (this.sortable) {
      this.sortable.destroy();
    }
  }),
});
