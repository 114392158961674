/* import __COLOCATED_TEMPLATE__ from './session-duration.hbs'; */
/* RESPONSIBLE TEAM: team-messenger */

import Component from '@glimmer/component';
import { action } from '@ember/object';
import { tracked } from '@glimmer/tracking';
import { inject as service } from '@ember/service';
import type IntlService from 'embercom/services/intl';
import type Security from 'embercom/models/messenger-settings/security';
import {
  MILLISECONDS,
  MIN_DURATION,
  MAX_DURATION,
  DEFAULT_DURATION,
} from 'embercom/models/messenger-settings/security';

interface Args {
  securitySettings: Security;
}

export default class SessionDuration extends Component<Args> {
  @service declare intl: IntlService;
  @tracked durationValue = this.getDurationValue();
  @tracked durationUnit = this.getDurationUnit();
  @tracked previousDuration: number | null = null;
  @tracked isValid = true;

  get isEnabled() {
    return this.args.securitySettings.sessionDuration !== null;
  }

  get timeUnits() {
    return [
      {
        text: this.intl.t('messenger.security-settings.session-duration.units.hours'),
        value: 'hour',
      },
      {
        text: this.intl.t('messenger.security-settings.session-duration.units.days'),
        value: 'day',
      },
      {
        text: this.intl.t('messenger.security-settings.session-duration.units.weeks'),
        value: 'week',
      },
    ];
  }

  getDurationValue(): number {
    let ms = this.args.securitySettings.sessionDuration;
    if (!ms) {
      return 7; // Default to 7 when enabling
    }
    let unit = this.getDurationUnit();
    return Math.floor(ms / MILLISECONDS[unit]);
  }

  getDurationUnit(): keyof typeof MILLISECONDS {
    let ms = this.args.securitySettings.sessionDuration;
    if (!ms) {
      return 'day'; // Default to days when enabling
    }

    if (ms >= MILLISECONDS.week) {
      return 'week';
    }
    if (ms >= MILLISECONDS.day) {
      return 'day';
    }
    if (ms >= MILLISECONDS.hour) {
      return 'hour';
    }
    return 'minute';
  }

  @action
  toggleEnabled() {
    if (this.isEnabled) {
      this.previousDuration = this.args.securitySettings.sessionDuration;
      this.args.securitySettings.sessionDuration = null;
    } else {
      this.args.securitySettings.sessionDuration = this.previousDuration || DEFAULT_DURATION;
      this.durationValue = this.getDurationValue();
      this.durationUnit = this.getDurationUnit();
    }
  }

  private updateSessionDuration() {
    let ms = this.durationValue * MILLISECONDS[this.durationUnit];
    this.args.securitySettings.sessionDuration = ms || null;
    this.isValid = this.args.securitySettings.isSessionDurationValid;
  }

  @action
  setDurationValue(event: InputEvent) {
    let target = event.target as HTMLInputElement;
    if (target.value === '') {
      return;
    }

    let value = parseInt(target.value, 10);
    this.durationValue = value;
    this.updateSessionDuration();
  }

  @action
  setDurationUnit(unit: keyof typeof MILLISECONDS) {
    this.durationUnit = unit;
    this.updateSessionDuration();
  }

  get maxValueForUnit() {
    return Math.floor(MAX_DURATION / MILLISECONDS[this.durationUnit]);
  }

  get minValueForUnit() {
    let value = MIN_DURATION / MILLISECONDS[this.durationUnit];
    // If the minimum duration is less than one unit, return 1
    return Math.max(1, Math.floor(value));
  }

  get validationMessage(): string | null {
    return this.args.securitySettings.sessionDurationValidationMessage;
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'MessengerSettings::Security::SessionDuration': typeof SessionDuration;
  }
}
