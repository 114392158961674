/* import __COLOCATED_TEMPLATE__ from './privacy-policy-notice.hbs'; */
/* RESPONSIBLE TEAM: team-messenger */
import Component from '@glimmer/component';
import type PrivacyPolicyNoticeModel from 'embercom/models/messenger-settings/privacy-policy-notice';
import type SecurityModel from 'embercom/models/messenger-settings/security';
import { hasMobileSdkInstalled } from 'embercom/lib/mobile/sdk-version-check';
import { inject as service } from '@ember/service';

type SimpleLocale = { localeId: string; isPermitted: boolean; name: string };

export interface Args {
  securitySettings: SecurityModel;
  privacyPolicyNotice: PrivacyPolicyNoticeModel;
  languages: {
    supportedLocales: Array<SimpleLocale>;
    availableLocales: Array<SimpleLocale>;
    defaultLocale: string;
  };
}

export default class PrivacyPolicyNotice extends Component<Args> {
  @service declare appService: $TSFixMe;

  get app() {
    return this.appService.app;
  }

  get hasMobileSdkInstalled() {
    return hasMobileSdkInstalled(this.app);
  }

  get languages() {
    return this.args.languages;
  }

  get permittedLocales() {
    let defaultLocale = this.languages.supportedLocales.findBy(
      'localeId',
      this.languages.defaultLocale,
    );

    let otherPermittedLocales = this.languages.supportedLocales.filter(
      (locale) => locale.localeId !== this.languages.defaultLocale && locale.isPermitted,
    );
    return [defaultLocale, ...otherPermittedLocales].compact();
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'MessengerSettings::General::PrivacyPolicyNotice': typeof PrivacyPolicyNotice;
    'messenger-settings/general/privacy-policy-notice': typeof PrivacyPolicyNotice;
  }
}
