/* import __COLOCATED_TEMPLATE__ from './url-form.hbs'; */
/* RESPONSIBLE TEAM: team-knowledge-foundations */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable @intercom/intercom/no-default-task-ember-concurrency */
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { inject as service } from '@ember/service';
import { task } from 'ember-concurrency-decorators';
import isValidUrl from 'embercom/lib/url-validator';
import { action } from '@ember/object';
import EnqueueApifyImportApi from 'embercom/lib/external-content/enqueue-apify-import-api';
import { getOwner } from '@ember/application';

interface Args {
  onCloseModal: Function;
  fromOnboarding: boolean;
}
export default class UrlForm extends Component<Args> {
  @service declare appService: any;
  @service contentImportService: any;
  @service intercomEventService: any;

  @tracked url = '';
  @tracked submitted = false;
  @tracked submissionError = false;
  @tracked submissionErrorTranslationKey = 'modal.import-external-content.url-form.errors.default';

  get app() {
    return this.appService.app;
  }

  get isUrlInvalid() {
    return !isValidUrl(this.url);
  }

  @task
  *enqueueApifyImport(): Generator<any> {
    this.trackAnalyticsEvent('submitted', 'url_submit_button');

    if (this.isUrlInvalid) {
      return;
    }

    let api = new EnqueueApifyImportApi(getOwner(this));
    let { submitted, submissionError, submissionErrorTranslationKey }: any =
      yield api.enqueueApifyImport(this.url, this.args.fromOnboarding);

    this.submissionError = submissionError;
    this.submissionErrorTranslationKey = submissionErrorTranslationKey;

    if (submitted) {
      this.args.onCloseModal();
    }
  }

  @action trackInputAnalytics() {
    this.trackAnalyticsEvent('inputed', 'url_form_input');
  }

  private trackAnalyticsEvent(action: string, object: string): void {
    this.intercomEventService.trackAnalyticsEvent({
      action,
      object,
      context: 'external_content_modal',
      place: 'fin_setup',
    });
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Modal::ImportExternalContent::UrlForm': typeof UrlForm;
    'modal/import-external-content/url-form': typeof UrlForm;
  }
}
