/* import __COLOCATED_TEMPLATE__ from './content.hbs'; */
/* RESPONSIBLE TEAM: team-messenger */
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';

export default class Content extends Component {
  @service('messenger-settings-service') settingsService;
  @service helpCenterService;
  @service appService;

  get app() {
    return this.appService.app;
  }

  get hasMultipleHelpCenterSites() {
    return this.helpCenterService.sites?.length > 1;
  }

  get layoutAndSpacesTitle() {
    if (this.args.isMobilePlatform) {
      return 'messenger.settings-layout.spaces.title';
    } else if (this.appService.app.canUseStandalone) {
      return 'messenger.settings-layout.layout.title';
    } else {
      return 'messenger.settings-layout.content.accordion.layout-and-spaces';
    }
  }

  get canSetHelpCenterSite() {
    // When multi brand email is enabled, associating a help center site with a brand is allowed only via Brands page
    if (this.appService.app.canUseMultiBrandEmailOpenBeta) {
      return false;
    }
    return this.hasMultipleHelpCenterSites;
  }
}
