/* import __COLOCATED_TEMPLATE__ from './identity-verification-key-display.hbs'; */
/* RESPONSIBLE TEAM: team-messenger */
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import { action } from '@ember/object';
import type RouterService from '@ember/routing/router-service';
import type MessengerSecurityIdvSetting from 'embercom/models/messenger-security/idv-setting';

interface Args {
  keys: MessengerSecurityIdvSetting[];
}

export default class IdentityVerificationKeyDisplay extends Component<Args> {
  @service declare router: RouterService;

  get activeKeys() {
    return this.args.keys.sortBy('createdAt').reverse();
  }

  @action
  navigateToKeyManagement() {
    this.router.transitionTo('apps.app.settings.workspace.security', {
      queryParams: { section: 'messenger-security' },
    });
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'MessengerSettings::Security::IdentityVerificationKeyDisplay': typeof IdentityVerificationKeyDisplay;
    'messenger-settings/security/identity-verification-key-display': typeof IdentityVerificationKeyDisplay;
  }
}
