/* import __COLOCATED_TEMPLATE__ from './identity-verification-jwt-verifier.hbs'; */
/* RESPONSIBLE TEAM: team-app-security */

import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import { tracked } from '@glimmer/tracking';
import { action } from '@ember/object';
import type IntlService from 'embercom/services/intl';
import type RouterService from '@ember/routing/router-service';
import type IdvSetting from 'embercom/models/messenger-security/idv-setting';
import { getJwtErrorStampsMap, getStampForErrorType } from 'embercom/utils/jwt-error-stamps';
import ajax from 'embercom/lib/ajax';

interface DecodedJWT {
  header: Record<string, unknown>;
  payload: Record<string, unknown>;
  signature: string;
}

interface SelectOption {
  text: string;
  value: string;
}

interface Args {
  keys: IdvSetting[];
}

interface VerificationResult {
  verified: boolean;
  error_name?: string;
  error_message?: string;
  error_code?: string;
}

export default class IdentityVerificationJwtVerifier extends Component<Args> {
  @service declare intl: IntlService;
  @service declare appService: any;
  @service declare router: RouterService;

  @tracked jwtInput = '';
  @tracked decodedJwt: DecodedJWT | null = null;
  @tracked hasError = false;
  @tracked errorMessage = '';
  @tracked selectedKeyId: string | null = null;
  @tracked isVerifying = false;
  @tracked verificationResult: VerificationResult | null = null;
  @tracked showDecodedContent = false;

  get errorStampsMap() {
    return getJwtErrorStampsMap(this.intl);
  }

  get app() {
    return this.appService.app;
  }

  get appId() {
    return this.app.id;
  }

  get hasKeys(): boolean {
    return this.args.keys.length > 0;
  }

  get keyOptions(): SelectOption[] {
    return this.args.keys.map((key) => ({
      text: key.name,
      value: key.id,
    }));
  }

  get formattedHeader(): string {
    return this.decodedJwt ? JSON.stringify(this.decodedJwt.header, null, 2) : '';
  }

  get formattedPayload(): string {
    return this.decodedJwt ? JSON.stringify(this.decodedJwt.payload, null, 2) : '';
  }

  get isVerified(): boolean {
    return this.verificationResult?.verified === true;
  }

  get canVerify(): boolean {
    return !!this.jwtInput && !!this.selectedKeyId && !this.hasError;
  }

  get translatedErrorMessage(): string {
    if (!this.verificationResult || this.verificationResult.verified) {
      return '';
    }

    if (this.verificationResult.error_name) {
      return getStampForErrorType(
        this.verificationResult.error_name,
        this.errorStampsMap,
        this.intl,
      ).value;
    }

    return this.intl.t('apps.app.settings.security.jwt-verifier.unknown-error');
  }

  @action
  selectKey(keyId: string): void {
    this.selectedKeyId = keyId;
  }

  @action
  updateJwtInput(event: InputEvent): void {
    this.jwtInput = (event.target as HTMLInputElement).value;
    this.decodeJwt();
    this.verificationResult = null;
    this.showDecodedContent = false;
  }

  private decodeJwt(): void {
    if (!this.jwtInput) {
      this.decodedJwt = null;
      this.hasError = false;
      this.verificationResult = null;
      return;
    }

    try {
      let parts = this.jwtInput.split('.');

      if (parts.length !== 3) {
        this.decodedJwt = null;
        this.hasError = true;
        this.errorMessage = this.intl.t(
          'apps.app.settings.security.jwt-verifier.invalid-jwt-format',
        );
        this.verificationResult = null;
        return;
      }

      let [headerB64, payloadB64, signature] = parts;

      if (!headerB64 || !payloadB64 || !signature) {
        this.decodedJwt = null;
        this.hasError = true;
        this.errorMessage = this.intl.t(
          'apps.app.settings.security.jwt-verifier.invalid-jwt-format',
        );
        this.verificationResult = null;
        return;
      }

      let base64UrlDecode = (str: string): string => {
        let base64 = str.replace(/-/g, '+').replace(/_/g, '/');
        while (base64.length % 4) {
          base64 += '=';
        }
        return atob(base64);
      };

      let header: Record<string, unknown>;
      let payload: Record<string, unknown>;

      try {
        let headerStr = base64UrlDecode(headerB64);
        header = JSON.parse(headerStr);
      } catch (error) {
        this.decodedJwt = null;
        this.hasError = true;
        this.errorMessage = this.intl.t(
          'apps.app.settings.security.jwt-verifier.invalid-jwt-format',
        );
        this.verificationResult = null;
        return;
      }

      try {
        let payloadStr = base64UrlDecode(payloadB64);
        payload = JSON.parse(payloadStr);
      } catch (error) {
        this.decodedJwt = null;
        this.hasError = true;
        this.errorMessage = this.intl.t(
          'apps.app.settings.security.jwt-verifier.invalid-jwt-format',
        );
        this.verificationResult = null;
        return;
      }

      this.decodedJwt = {
        header,
        payload,
        signature,
      };

      this.hasError = false;
      this.errorMessage = '';
    } catch (error) {
      this.decodedJwt = null;
      this.hasError = true;
      this.errorMessage = this.intl.t('apps.app.settings.security.jwt-verifier.invalid-jwt-format');
      this.verificationResult = null;
    }
  }

  @action
  async verifyJwtWithBackend(): Promise<void> {
    if (!this.canVerify) {
      return;
    }

    this.isVerifying = true;
    this.showDecodedContent = true;

    try {
      let result = await ajax({
        url: '/ember/jwt/verify',
        type: 'POST',
        data: JSON.stringify({
          jwt: this.jwtInput,
          key_id: this.selectedKeyId,
          app_id: this.appId,
        }),
      });

      this.verificationResult = result;
    } catch (error) {
      console.error('Error verifying JWT:', error);
      this.verificationResult = {
        verified: false,
        // This is an internal error identifier that gets translated through the errorStampsMap
        // and is never displayed directly to users
        // eslint-disable-next-line @intercom/intercom/no-bare-strings
        error_name: 'Unknown error',
      };
    } finally {
      this.isVerifying = false;
    }
  }

  @action
  navigateToKeyManagement() {
    this.router.transitionTo('apps.app.settings.workspace.security', {
      queryParams: { section: 'messenger-security' },
    });
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'MessengerSettings::Security::IdentityVerificationJwtVerifier': typeof IdentityVerificationJwtVerifier;
    'messenger-settings/security/identity-verification-jwt-verifier': typeof IdentityVerificationJwtVerifier;
  }
}
