/* import __COLOCATED_TEMPLATE__ from './configuration-pane-platform.hbs'; */
/* RESPONSIBLE TEAM: team-messenger */
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import moment from 'moment-timezone';
import type IntlService from 'ember-intl/services/intl';
import type MessengerSettingsService from 'embercom/services/messenger-settings-service';
import type LookAndFeelModel from 'embercom/models/messenger-settings/look-and-feel';
import type BrandSettings from 'embercom/models/brand-settings';

export type Args = {
  model: { lookAndFeel: LookAndFeelModel }; // app/models/messenger-settings/all.js
  platform: string;
  identity: $TSFixMe; // app/models/operator-identity.js
  newSettingsDesign: boolean;
};

export default class ConfigurationPanePlatform extends Component<Args> {
  @service declare appService: {
    app: {
      hasBeenOnMultibrandBeta: boolean;
      canUseMultiBrandEmailOpenBeta: boolean;
      isStandaloneApp: boolean;
      created_at: string;
    };
  };
  @service('messenger-settings-service') declare settingsService: MessengerSettingsService;
  @service declare intl: IntlService;

  MULTIBRAND_LAUNCHED_TO_NEW_APPS_DATE = '2025-01-31';

  get canShowMultiBrandInfoBanner() {
    // Don't show the banner if the workspace has ever been on the multibrand beta
    // as these apps have already configured the mobiel SDK styles correctly during the Beta
    if (this.appService.app.hasBeenOnMultibrandBeta) {
      return false;
    }

    // Don't show the banner if multibrand beta FF is not enabled (will be globally enabled soon)
    // This check is there to prevent the banner from showing until we go live to all
    if (!this.appService.app.canUseMultiBrandEmailOpenBeta) {
      return false;
    }

    if (this.appService.app.isStandaloneApp) {
      return false;
    }

    // Don't show the banner if the app was created after the multibrand launch
    if (this.isAppCreatedAfterMultibrandLaunch) {
      return false;
    }

    if (!this.hasMobileBrandSettings && this.args.platform === 'mobile') {
      return false;
    }

    return true;
  }

  get hasMobileBrandSettings() {
    return (
      this.args.model.lookAndFeel.brands.filter(
        (brand: BrandSettings) => brand.platform === 'mobile',
      ).length > 1
    );
  }

  get isAppCreatedAfterMultibrandLaunch() {
    return moment(this.appService.app.created_at).isSameOrAfter(
      this.MULTIBRAND_LAUNCHED_TO_NEW_APPS_DATE,
    );
  }

  get bannerDescription1() {
    if (!this.hasMobileBrandSettings) {
      return this.intl.t('messenger.settings-layout.configuration-pane.banner-description-web-1', {
        htmlSafe: true,
        isStandalone: this.appService.app.isStandaloneApp,
      });
    }
    return this.intl.t(
      'messenger.settings-layout.configuration-pane.banner-description-mobile-sdk-1',
    );
  }

  get bannerDescription2() {
    return this.intl.t(
      'messenger.settings-layout.configuration-pane.banner-description-mobile-sdk-2',
    );
  }

  get canShowBannerDescription2() {
    return this.hasMobileBrandSettings;
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'MessengerSettings::ConfigurationPanePlatform': typeof ConfigurationPanePlatform;
    'messenger-settings/configuration-pane-platform': typeof ConfigurationPanePlatform;
  }
}
