/* import __COLOCATED_TEMPLATE__ from './install-method-selector-card.hbs'; */
/* RESPONSIBLE TEAM: team-messenger */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file: 🚀 */
/* eslint-disable ember/no-classic-classes */
/* eslint-disable ember/no-classic-components */
/* eslint-disable @intercom/intercom/require-empty-tagname */
import Component from '@ember/component';
import { computed } from '@ember/object';

export default Component.extend({
  attributeBindings: ['data-test-install-method-selector-card'],
  'data-test-install-method-selector-card': true,
  classNames: [
    'f__horizontal-select',
    'onboarding__home__install-method-card__component',
    'flex-auto',
  ],
  classNameBindings: ['selected:o__selected'],

  iconAppearance: computed('selected', 'hover', function () {
    if (this.selected || this.hover) {
      return 'blue';
    }
    return 'gray';
  }),

  click() {
    this.onClick();
  },

  mouseEnter() {
    this.set('hover', true);
  },

  mouseLeave() {
    this.set('hover', false);
  },
});
