/* import __COLOCATED_TEMPLATE__ from './ios-component.hbs'; */
/* RESPONSIBLE TEAM: team-messenger */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable @intercom/intercom/no-bare-strings */
/* eslint-disable @intercom/intercom/no-component-inheritance */
import BaseInstallMethodComponent from 'embercom/components/messenger-installation/install-methods/base-install-method-component';
import { equal, or } from '@ember/object/computed';
import documentationMetadata from 'embercom/helpers/documentation-metadata';
import { computed, action } from '@ember/object';
import { A } from '@ember/array';

export default BaseInstallMethodComponent.extend({
  language: 'swift',
  hmacServerSideLanguage: 'node',
  exampleIsSwift: equal('language', 'swift'),
  options: [
    { text: 'Swift', value: 'swift' },
    { text: 'Objective-C', value: 'objectivec' },
  ],
  infoPlistLinkMetadata: documentationMetadata('info_plist'),
  sdkApiKey: or('apiKey', 'iOSSdkApp.api_key'),
  sdkApiSecret: or('apiSecret', 'iOSSdkApp.api_secret'),
  secretKeys: computed('sdkApiSecret', function () {
    return A([{ userSource: 'iOS', key: this.sdkApiSecret }]);
  }),

  identificationHMACGenerator: 'user_id',

  changeHMACGenerator: action(function (identifier) {
    this.set('identificationHMACGenerator', identifier);
  }),
});
