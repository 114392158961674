/* import __COLOCATED_TEMPLATE__ from './modal.hbs'; */
/* RESPONSIBLE TEAM: team-knowledge-foundations */
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { inject as service } from '@ember/service';
import { action } from '@ember/object';
import type IntlService from 'ember-intl/services/intl';

interface Args {
  showImportExternalContentModal: boolean;
  onCloseModal: Function;
  forceShowUrlImportContentModal: boolean;
}

export default class ArticleImportModal extends Component<Args> {
  @service intl!: IntlService;
  @service intercomEventService: any;
  @tracked currentView = 'default';

  get title() {
    return this.intl.t(`modal.import-external-content.${this.currentView}.title`);
  }

  get subtitle() {
    return this.intl.t(`modal.import-external-content.${this.currentView}.subtitle`, {
      htmlSafe: true,
    });
  }

  @action closeModal() {
    this.currentView = 'default';
    this.args.onCloseModal();
  }

  @action importExternalContent() {
    this.currentView = 'url-form';
    this.trackAnalytics('import_content_card');
  }

  @action trackAnalytics(object: any) {
    this.intercomEventService.trackAnalyticsEvent({
      action: 'clicked',
      object,
      context: 'external_content_modal',
      place: 'fin_setup',
    });
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Modal::ImportExternalContent::Modal': typeof ArticleImportModal;
  }
}
