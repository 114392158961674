/* import __COLOCATED_TEMPLATE__ from './show-launcher.hbs'; */
/* RESPONSIBLE TEAM: team-messenger */

import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import Component from '@glimmer/component';
// @ts-expect-error No types declaration for ember-copy yet.
import { copy } from 'ember-copy';
import { EXTRA_USER_ATTRIBUTES } from 'embercom/lib/messenger/installation-constants';
import type ArrayProxy from '@ember/array/proxy';
import type Model from '@ember-data/model';
import type EmberObject from '@ember/object';
import type IntlService from 'ember-intl/services/intl';
import { type Predicate } from 'embercom/lib/knowledge-hub/constants';
import type MessengerSettingsTargetUserProvider from 'embercom/services/messenger-settings-target-user-provider';
import type FinStandaloneService from 'embercom/services/fin-standalone-service';

export interface Args {
  isMobilePlatform: boolean;
  visibilitySettings: Model & {
    messengerEnabled: boolean;
    launcherPredicates: ArrayProxy<Predicate>;
    launcherPredicatesForAnonymous: ArrayProxy<Predicate>;
    launcherPredicatesForUsers: ArrayProxy<Predicate>;
  };
}

export default class ShowLauncherSettingsComponent extends Component<Args> {
  @service declare appService: { app: { canUseStandalone: boolean } };
  @service declare attributeService: $TSFixMe;
  @service declare finStandaloneService: FinStandaloneService;
  @service declare messengerSettingsTargetUserProvider: MessengerSettingsTargetUserProvider;
  @service declare intl: IntlService;

  get isUserType() {
    return this.messengerSettingsTargetUserProvider.isUserType;
  }

  get userAttributes() {
    return this.attributeService.filterableUserAttributesMaybeWithSegmentsAndTags.filter(
      (attribute: { isUntrackedCda: boolean }) => !attribute.isUntrackedCda,
    );
  }

  get companyAttributes() {
    return this.attributeService.filterableCompanyAttributesMaybeWithPlansSegmentsAndTags;
  }

  get messengerConditionalDisplayAttributes(): ArrayProxy<EmberObject & { name: string }> {
    return this.attributeService.messengerConditionalDisplayAttributes;
  }

  get attributeGroupList() {
    if (this.messengerSettingsTargetUserProvider.isUserType) {
      return this.attributeGroupListForUsers;
    }
    return this.attributeGroupListForVisitors;
  }

  get attributeGroupListForUsers() {
    return [
      {
        heading: this.intl.t('messenger.predicates.user-data'),
        attributes: [
          ...this.messengerConditionalDisplayAttributes.filter((el) => {
            return this.args.isMobilePlatform
              ? false
              : EXTRA_USER_ATTRIBUTES.includes(el.get('name'));
          }),
          ...this.userAttributes,
        ],
      },
      {
        heading: this.intl.t('messenger.predicates.company-data'),
        attributes: this.companyAttributes,
      },
    ];
  }

  get attributeGroupListForVisitors() {
    return [
      {
        heading: this.intl.t('messenger.predicates.person-data'),
        attributes: this.messengerConditionalDisplayAttributes,
      },
    ];
  }

  get messengerEnabled() {
    return this.args.visibilitySettings.messengerEnabled;
  }

  get launcherPredicates() {
    return this.args.visibilitySettings.launcherPredicates;
  }

  get isMessengerInTestMode() {
    if (!this.appService.app.canUseStandalone) {
      return false;
    }
    return this.finStandaloneService.intercomMessengerConfig.isMessengerInTestMode;
  }

  @action
  toggleMessengerEnabled() {
    this.args.visibilitySettings.messengerEnabled = !this.messengerEnabled;
  }

  @action
  updatePredicates(predicates: Predicate[]) {
    this.args.visibilitySettings.launcherPredicates.clear();
    let newPredicates = copy(predicates);
    this.args.visibilitySettings.launcherPredicates.pushObjects(newPredicates);
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'MessengerSettings::Content::ShowLauncher': typeof ShowLauncherSettingsComponent;
    'messenger-settings/content/show-launcher': typeof ShowLauncherSettingsComponent;
  }
}
