/* import __COLOCATED_TEMPLATE__ from './installation-snippet.hbs'; */
/* RESPONSIBLE TEAM: team-messenger */
import { inject as service } from '@ember/service';
import Component from '@glimmer/component';
import {
  INSTALLATION_METHOD,
  PROGRAMMING_LANGUAGES,
} from 'embercom/components/installation-new/constants';

interface Signature {
  Args: {
    method: INSTALLATION_METHOD | PROGRAMMING_LANGUAGES;
  };
}

type Args = Signature['Args'];

export default class InstallationSnippet extends Component<Signature> {
  @service appService: any;
  @service regionService: any;

  constructor(owner: never, args: Args) {
    super(owner, args);
  }

  get app() {
    return this.appService.app;
  }

  get language() {
    // Override the language for Django
    // As it's more legible in Python
    if (this.args.method === PROGRAMMING_LANGUAGES.DJANGO) {
      return 'python';
    }

    if (Object.values(PROGRAMMING_LANGUAGES).includes(this.args.method as PROGRAMMING_LANGUAGES)) {
      return this.args.method;
    }

    return 'javascript';
  }

  private getNpmSnippet(): string {
    return `
Intercom({
  app_id: "${this.app.id}",
  intercom_user_jwt: "<TOKEN>",               // Required - JWT token generated on your server
  non_sensitive_attribute1: "non-sensitive",  // Optional
  non_sensitive_attribute2: "non-sensitive",  // Optional
  non_sensitive_attribute3: "non-sensitive",  // Optional
  session_duration: 86400000                  // 1 day (Optional)
});`;
  }

  get codeSnippet(): string {
    switch (this.args.method) {
      case INSTALLATION_METHOD.CODE_SNIPPET:
        return `
<script>
  window.Intercom("boot", {
    api_base: "${this.regionService.apiBaseURL}",
    app_id: "${this.app.id}",
    intercom_user_jwt: "<TOKEN>",               // Required - JWT token generated on your server
    non_sensitive_attribute1: "non-sensitive",  // Optional
    non_sensitive_attribute2: "non-sensitive",  // Optional
    non_sensitive_attribute3: "non-sensitive",  // Optional
    session_duration: 86400000                  // 1 day (Optional)
  });
</script>
<script>
  // We pre-filled your app ID in the widget URL: 'https://widget.intercom.io/widget/${this.app.id}'
  (function(){var w=window;var ic=w.Intercom;if(typeof ic==="function"){ic('reattach_activator');ic('update',w.intercomSettings);}else{var d=document;var i=function(){i.c(arguments);};i.q=[];i.c=function(args){i.q.push(args);};w.Intercom=i;var l=function(){var s=d.createElement('script');s.type='text/javascript';s.async=true;s.src='https://widget.intercom.io/widget/${this.app.id}';var x=d.getElementsByTagName('script')[0];x.parentNode.insertBefore(s,x);};if(document.readyState==='complete'){l();}else if(w.attachEvent){w.attachEvent('onload',l);}else{w.addEventListener('load',l,false);}}})();
</script>`;

      case INSTALLATION_METHOD.REACT:
      case INSTALLATION_METHOD.ANGULAR:
      case INSTALLATION_METHOD.VUE:
      case INSTALLATION_METHOD.EMBER:
        return this.getNpmSnippet();

      case PROGRAMMING_LANGUAGES.NODE:
        return `
const jwt = require('jsonwebtoken');

const payload = {
  user_id: '<YOUR_USER_ID>',              // Required
  email: '<YOUR_USER_EMAIL>',             // Optional
  sensitive_attribute1: 'sensitive-data', // Optional
  sensitive_attribute2: 'sensitive-data', // Optional
  sensitive_attribute3: 'sensitive-data', // Optional
};

const token = jwt.sign(payload, '<YOUR_API_SECRET>', { expiresIn: '1h' });`;

      case PROGRAMMING_LANGUAGES.RAILS:
        return `
require 'jwt'

class IntercomController < ApplicationController
  def generate_token
    payload = {
      user_id: '<YOUR_USER_ID>',              // Required
      email: '<YOUR_USER_EMAIL>',             // Optional
      sensitive_attribute1: 'sensitive_data', // Optional
      sensitive_attribute2: 'sensitive_data', // Optional
      sensitive_attribute3: 'sensitive_data', // Optional
      exp: Time.now.to_i + 3600               // Expires in 1 hour
    }

    @token = JWT.encode(payload, '<YOUR_API_SECRET>', 'HS256')
  end
end`;

      case PROGRAMMING_LANGUAGES.DJANGO:
        return `
import jwt
from datetime import datetime, timedelta

def generate_intercom_token(request):
    expires_at = int((datetime.now() + timedelta(hours=1)).timestamp())
    payload = {
        'user_id': '<YOUR_USER_ID>',              // Required
        'email': '<YOUR_USER_EMAIL>',             // Optional
        'sensitive_attribute1': 'sensitive_data', // Optional
        'sensitive_attribute2': 'sensitive_data', // Optional
        'sensitive_attribute3': 'sensitive_data', // Optional
        'exp': expires_at                         // Expires in 1 hour
    }

    return jwt.encode(payload, '<YOUR_API_SECRET>', algorithm='HS256')`;

      case PROGRAMMING_LANGUAGES.PHP:
        return `
use \Firebase\JWT\JWT;

$payload = [
    "user_id" => "<YOUR_USER_ID>",              // Required
    "email" => "<YOUR_USER_EMAIL>",             // Optional
    "sensitive_attribute1" => "sensitive_data", // Optional
    "sensitive_attribute2" => "sensitive_data", // Optional
    "sensitive_attribute3" => "sensitive_data", // Optional
    "exp" => time() + 3600                      // Expires in 1 hour
];

$token = JWT::encode($payload, "<YOUR_API_SECRET>", "HS256");`;

      case PROGRAMMING_LANGUAGES.GO:
        return `
package main

import (
    "time"
    "github.com/golang-jwt/jwt"
)

func generateIntercomToken() (string, error) {
    now := time.Now()
    claims := jwt.MapClaims{
        "user_id": "<YOUR_USER_ID>",              // Required
        "email": "<YOUR_USER_EMAIL>",             // Optional
        "sensitive_attribute1": "sensitive_data", // Optional
        "sensitive_attribute2": "sensitive_data", // Optional
        "sensitive_attribute3": "sensitive_data", // Optional
        "exp": now.Add(time.Hour).Unix(),         // Expires in 1 hour
    }

    token := jwt.NewWithClaims(jwt.SigningMethodHS256, claims)
    return token.SignedString([]byte("<YOUR_API_SECRET>"))
}`;

      case PROGRAMMING_LANGUAGES.JAVA:
        return `
import io.jsonwebtoken.Jwts;
import io.jsonwebtoken.SignatureAlgorithm;
import java.util.Date;
import java.util.HashMap;

Map<String, Object> claims = new HashMap<>();
claims.put("user_id", "<YOUR_USER_ID>");              // Required
claims.put("email", "<YOUR_USER_EMAIL>");             // Optional
claims.put("sensitive_attribute1", "sensitive_data"); // Optional
claims.put("sensitive_attribute2", "sensitive_data"); // Optional
claims.put("sensitive_attribute3", "sensitive_data"); // Optional

String token = Jwts.builder()
    .setClaims(claims)
    .setExpiration(new Date(System.currentTimeMillis() + 3600000))  // Expires in 1 hour
    .signWith(SignatureAlgorithm.HS256, "<YOUR_API_SECRET>")
    .compact();`;

      default:
        return '';
    }
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'MessengerSettings::Security::CodeSnippets::InstallationSnippet': typeof InstallationSnippet;
    'messenger-settings/security/code-snippets/installation-snippet': typeof InstallationSnippet;
  }
}
