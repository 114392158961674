/* RESPONSIBLE TEAM: team-messenger */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file: 🚀 */
/* eslint-disable ember/no-classic-classes */
/* eslint-disable ember/no-classic-components */
/* eslint-disable @intercom/intercom/require-empty-tagname */
/* eslint-disable ember/require-computed-property-dependencies */
import Component from '@ember/component';
import { inject as service } from '@ember/service';
import documentationMetadata from 'embercom/helpers/documentation-metadata';
import { computed } from '@ember/object';
import { reads } from '@ember/object/computed';
import { A } from '@ember/array';

export default Component.extend({
  intercomEventService: service(),

  baseAnalyticsMetadata: null,
  configurationLinkMetadata: documentationMetadata('configuration'),
  customizeMessengerLinkMetadata: documentationMetadata('customize_messenger'),
  installationLinkMetadata: documentationMetadata('installation'),
  registerUsersLinkMetadata: documentationMetadata('register_users'),
  troubleshootingLinkMetadata: documentationMetadata('troubleshooting'),
  userIdsLinkMetadata: documentationMetadata('user_ids'),
  appApiSecret: reads('app.api_secret'),
  secretKeys: computed('app.api_secret', function () {
    return A([{ userSource: 'web', key: this.appApiSecret }]);
  }),

  sendAnalyticsEvent(analyticsMetadata) {
    this.intercomEventService.trackAnalyticsEvent({
      ...this.baseAnalyticsMetadata,
      ...analyticsMetadata,
    });
  },

  didInsertElement() {
    this._super(...arguments);
    this.sendAnalyticsEvent({
      action: 'viewed',
      object: 'installation_method',
    });
  },

  get appID() {
    if (this.app) {
      return this.app.id;
    }
    return this.staticAppId;
  },
});
